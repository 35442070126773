<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Dati di spedizione</span>
    </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field v-model="form.nome" label="Nome*" :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="form.cognome" label="Cognome*" :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="form.nazione"
                :items="nazioni"
                label="Nazione*"
                :rules="[rules.required]"
                :item-text="'denominazione_nazione'"
                :item-value="'sigla_nazione'"
                @change="nazioneChange"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="form.provincia"
                :items="province"
                label="Provincia*"
                :rules="[rules.required]"
                :item-text="'denominazione_provincia'"
                :item-value="'sigla_provincia'"
                @change="provinciaChange"
                :disabled="form.nazione && form.nazione != 'IT'"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-if="!form.nazione || form.nazione == 'IT'"
                v-model="form.comune"
                :items="comuni"
                :item-text="'denominazione_ita'"
                :item-value="'codice_belfiore'"
                label="Comune*"
                :rules="[rules.required]"
                @change="changeComuni"
              ></v-select>
              <v-text-field v-else v-model="form.email" label="Comune*" :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select v-model="form.cap" :items="cap" label="CAP*" :rules="[rules.required]" :item-text="'cap'" :item-value="'cap'" :disabled="form.nazione && form.nazione != 'IT'"></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="form.indirizzo" label="Indirizzo*" :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="form.telefono" label="Telefono*" :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="form.email" label="email*" :rules="[rules.required, rules.email]"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <small>*campi obbligatori</small>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="close">
        Chiudi
      </v-btn>
      <v-btn color="blue darken-1" text @click="save">
        Completa l'acquisto
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import nazioni from "../utils/nazioni.json";
import province from "../utils/province.json";
import comuni from "../utils/comuni.json";
import cap from "../utils/cap.json";
import ordiniApi from "../api/ordiniApi";
export default {
  props: ["isPhone"],

  data() {
    return {
      rules: {
        required: value => !!value || "Il campo è obbligatorio",
        email: value => !value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || "email non valida"
      },
      nazioni: [],
      province: [],
      comuni: [],
      cap: [],
      form: {
        nome: "",
        cognome: "",
        nazione: null,
        provincia: null,
        comune: null,
        cap: null,
        indirizzo: "",
        telefono: "",
        email: ""
      }
    };
  },

  mounted() {
    this.nazioni = nazioni.sort((a, b) => {
      let fa = a.denominazione_cittadinanza.toLowerCase(),
        fb = b.denominazione_cittadinanza.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
  },

  computed: {},

  methods: {
    nazioneChange(nazione) {
      if (nazione == "IT") {
        this.province = province;
        this.province.sort((a, b) => {
          let fa = a.denominazione_provincia.toLowerCase(),
            fb = b.denominazione_provincia.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
      }
    },

    provinciaChange(provincia) {
      this.comuni = comuni.filter(comune => comune.sigla_provincia == provincia && comune.data_fine_validita == null);
      this.comuni.sort((a, b) => {
        let fa = a.denominazione_ita.toLowerCase(),
          fb = b.denominazione_ita.toLowerCase();
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
    },

    changeComuni(comune) {
      this.cap = cap.filter(cap => cap.codice_belfiore == comune);
    },

    async save() {
      if (!this.$refs.form.validate()) return;

      var nazione = this.nazioni.find(el => {
        if (this.form.nazione == el.sigla_nazione) return el;
      });
      var provincia = this.province.find(el => {
        if (this.form.provincia == el.sigla_provincia) return el;
      });
      var comune = this.comuni.find(el => {
        if (this.form.comune == el.codice_belfiore) return el;
      });

      var objForm = Object.assign({}, this.form);

      objForm.nazione = nazione.denominazione_nazione;
      objForm.provincia = provincia.denominazione_provincia;
      objForm.comune = comune.denominazione_ita;
      var ordine = await ordiniApi.set(objForm);

      localStorage.setItem("numeroOrdine", ordine.data._id);
      this.$emit("save", false);
    },

    close() {
      this.$emit("close", false);
    }
  }
};
</script>

<style lang="scss"></style>
