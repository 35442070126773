<template>
  <div style="display: flex">
    <div class="cntCarrello" v-if="!loading">
      <div v-if="!carrello.length" style="display: flex; justify-content: center">Il carrello è vuoto</div>
      <div v-else>
        <div v-for="item in carrello" :key="item._id" class="cntElement" @click="$router.push('/catalogo/' + item._id)">
          <div class="imgCarts">
            <img :src="item.foto1" />
          </div>
          <div class="cntInfo">
            <div class="nome" v-html="item.nome"></div>
            <div class="descrizione" v-html="item.descrizione"></div>
          </div>
          <div class="cntBtnRimuovi">
            <p class="font-weight-black">{{ getPrezzo(item.prezzo) }}</p>
            <v-btn fab dark small color="red" @click.stop="rimuoviItem(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="totale">
          <div style="text-align: right">Totale: {{ totaleCarrello }}</div>
          <v-dialog v-model="dialog" persistent max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="yellow" class="ma-2 font-weight-black btnAggiungi" v-bind="attrs" v-on="on">
                Prosegui
                <v-icon right dark>
                  mdi-cart-minus
                </v-icon>
              </v-btn>
            </template>
            <DatiSpedizioneModal @close="closeModal" @save="completaAcquisto" />
          </v-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import utils from "../utils/utils.js";
import pagamentoApi from "../api/pagamentoApi";
import DatiSpedizioneModal from "../components/DatiSpedizioneModal.vue";
export default {
  components: {
    DatiSpedizioneModal
  },

  data() {
    return {
      dialog: false
    }
  },

  computed: {
    ...mapGetters({
      carrello: "getCarrello",
      loading: "getLoading",
    }),

    totaleCarrello() {
      var tot = 0;
      for (var i = 0; i < this.carrello.length; i++) {
        tot += this.carrello[i].prezzo;
      }
      return utils.convertCurrency("it-IT", "EUR", tot);
    }
  },

  methods: {
    ...mapMutations(["deleteItemInCarrello"]),
    getPrezzo(prezzo) {
      return utils.convertCurrency("it-IT", "EUR", prezzo);
    },

    rimuoviItem(item) {
      this.deleteItemInCarrello(item);
    },

    async completaAcquisto() {
      var cartsCheckout = [];
      for (var i = 0; i < this.carrello.length; i++) {
        var item = this.carrello[i];
        var obj = {};
        obj["price_data"] = {
          currency: "eur",
          product_data: {
            name: item.nome.replace(/<\/?[^>]+(>|$)/g, "")
          },
          unit_amount: item.prezzo * 100
        };
        obj["quantity"] = 1;

        cartsCheckout.push(obj);
      }
      var res = await pagamentoApi.pagamento(cartsCheckout);
      window.open(res.data, "_self");
    },

    closeModal() {
      this.dialog = false;
    }
  }
};
</script>

<style lang="scss">
.cntCarrello {
  margin: 100px 30px;
  width: 100%;
  .cntElement {
    display: flex;
    padding: 20px 0;
    border-bottom: 1px solid white;
    height: 10rem;
    cursor: pointer;
    .imgCarts {
      display: flex;
      justify-content: center;
      img {
        height: 100%;
      }
    }
    .cntInfo {
      margin: 0 20px;
      width: 100%;
      .nome {
        font-weight: bold;
      }
      .descrizione {
        margin-top: 10px;
        overflow: hidden;
        white-space: pre-wrap;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        height: 73%;
      }
    }
    .cntBtnRimuovi {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
  .totale {
    font-size: 30px;
    font-weight: bold;
    margin-top: 20px;
    float: right;
    .btnAggiungi {
      float: right;
      margin: 8px 0 !important;
    }
  }
}

.phone {
  .cntCarrello {
    margin: 80px 20px;
    .cntElement {
      display: block;
      height: unset;
      .imgCarts {
        img {
          width: 100%;
        }
      }
      .cntInfo {
        margin: unset;
        margin-top: 10px;
        .descrizione {
          margin-top: 10px;
          width: 100%;
          height: 70%;
          font-size: 13px;
          -webkit-line-clamp: 4;
          height: 69%;
        }
      }
      .cntBtnRimuovi {
        font-size: 15px;
        margin-top: 10px;
      }
    }
    .totale {
      font-size: 20px;
    }
  }
}
</style>
