import axios from "axios";

export default {
  baseUrl: process.env.VUE_APP_API_BASE + "/ordini",

  async set(item) {
    try {
      var res = await axios.post(this.baseUrl, item);
      return res;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async update(item) {
    try {
      await axios.put(this.baseUrl, item);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async ordinePagato(id) {
    try {
      var res = await axios.put(this.baseUrl + "/ordinePagato", { _id: id });
      return res.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
};
