import axios from "axios";

export default {
  baseUrl: process.env.VUE_APP_API_BASE + "/pagamento",

  async pagamento(cartsCheckout) {
    try {
      var res = await axios.post(this.baseUrl, { cartsCheckout });
      return res;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
};
